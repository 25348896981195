import React from 'react';

import teamMom from './img/team-mom.png';
import teamDad from './img/team-dad.png';
import teamIya from './img/team-iya.png';

class SectionTeam extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section id="section-team">
                <h2>Team</h2>

                <div className="content-block">
                    <a href="https://twitter.com/dari_kko" target="_blank" rel="noopener noreferrer" className="team-member">
                        <img src={teamMom} />
                        Mother of Design
                    </a>

                    <a href="https://komliev.studio" target="_blank" rel="noopener noreferrer" className="team-member">
                        <img src={teamDad} />
                        Father of Development
                    </a>

                    <div className="team-member">
                        <img src={teamIya} />
                        Little DINO
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionTeam;

import React from 'react';
import Slider from "react-slick";

import dino from './img/dino.png';
import image1 from './img/image.jpg';
import image2 from './img/image1.jpg';
import image3 from './img/image2.jpg';
import image4 from './img/image3.png';
import image5 from './img/image.jpg';
import image6 from './img/image1.jpg';
import image7 from './img/image2.jpg';
import image8 from './img/image3.png';
import image9 from './img/image.jpg';
import image10 from './img/image1.jpg';
import image11 from './img/image2.jpg';

class SectionProject extends React.Component {
    constructor(props) {
        super(props);

        this.sliderSettings = {
            dots: false,
            arrows: false,
            centerMode: true,
            centerPadding: '10%',
            infinite: true,
            autoplay: true,
            autoplaySpeed: 0,
            cssEase: 'linear',
            speed: 8000,
            slidesToShow: 7,
            slidesToScroll: 1,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 7,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 540,
                    settings: {
                        slidesToShow: 2,
                    }
                },
            ]
        };
    }

    render() {
        return (
            <section id="section-project">
                <div id="side-links">
                    <ul>
                        <li><a href="https://opensea.io/collection/shorthandef" target="_blank" rel="noreferrer noopener">OpenSea</a></li>
                        <li><a href="https://twitter.com/shorthandef" target="_blank" rel="noreferrer noopener">Twitter</a></li>
                    </ul>
                </div>

                <div className="content-block">
                    <div className="price-container">
                        50<br />eth
                        <img src={dino} className="dino-image" />
                    </div>

                    <h1 className="h3">Welcome to the <br className="d-none d-lg-block" />short hand era foundation</h1>
                    <p>SHEF is a collection of 3,500 SHORT HAND NFTs — unique digital assets which are surviving in Ethereum blockchain. Short Hand living in an Era of The Russo-Ukrainian War and wanna help Ukraine. Its goal is to raise 50 ETH for 5 Ukrainian funds. What? 50 ETH? Yeap, Short Hand don't mean Weak Hand. These months of training and we are ready to lift such a large amount along with your Short Hands.</p>

                    <p className="lead">No Discord<br />just a silence</p>
                </div>

                <div className="content-slider">
                    <Slider {...this.sliderSettings}>
                        <div className="slider-item"><a href="#"><img src={image1} /></a></div>
                        <div className="slider-item"><a href="#"><img src={image2} /></a></div>
                        <div className="slider-item"><a href="#"><img src={image3} /></a></div>
                        <div className="slider-item"><a href="#"><img src={image4} /></a></div>
                        <div className="slider-item"><a href="#"><img src={image5} /></a></div>
                        <div className="slider-item"><a href="#"><img src={image6} /></a></div>
                        <div className="slider-item"><a href="#"><img src={image7} /></a></div>
                        <div className="slider-item"><a href="#"><img src={image8} /></a></div>
                        <div className="slider-item"><a href="#"><img src={image9} /></a></div>
                        <div className="slider-item"><a href="#"><img src={image10} /></a></div>
                        <div className="slider-item"><a href="#"><img src={image11} /></a></div>
                    </Slider>
                    <p>Note: Buying a DINO costs 0.024 ETH.</p>
                </div>
            </section>
        );
    }
}

export default SectionProject;

import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/base/theme.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./css/styles/styles.css";
import "./css/styles/styles-xs.css";
import "./css/styles/styles-sm.css";
import "./css/styles/styles-md.css";
import "./css/styles/styles-lg.css";
import "./css/styles/styles-xl.css";
import "./css/styles/styles-xxl.css";
import "./css/styles/styles-xxxl.css";

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

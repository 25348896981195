import React from 'react';

import logo from './img/logo-footer.png';
import twitterLogo from './img/twitter.png';
import openseaLogo from './img/opensea.png';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.scrollTo = this.scrollTo.bind(this);
    }


    scrollTo(e) {
        e.preventDefault();

        const domID = e.currentTarget.getAttribute('href');
        this.props.scrollTo(domID);
    }

    render() {
        return (
            <footer>
                <div className="footer-top">
                    <a href="/" className="logo"><img src={logo} /></a>

                    <ul>
                        <li><a href="#section-project" onClick={this.scrollTo}>Project</a></li>
                        <li><a href="#section-roadmap" onClick={this.scrollTo}>Roadmap</a></li>
                        <li><a href="#section-constructor" onClick={this.scrollTo}>Constructor</a></li>
                        <li><a href="#section-team" onClick={this.scrollTo}>Team</a></li>
                    </ul>

                    <a href="https://opensea.io/collection/shorthandef" target="_blank" rel="noreferrer noopener" className="cta-link">Buy a DINO</a>
                </div>
                <div className="footer-top-mobile">
                    <a href="https://opensea.io/collection/shorthandef" target="_blank" rel="noreferrer noopener" className="cta-link">Buy a DINO</a>

                    <ul>
                        <li><a href="#section-project" onClick={this.scrollTo}>Project</a></li>
                        <li><a href="#section-roadmap" onClick={this.scrollTo}>Roadmap</a></li>
                        <li><a href="#section-constructor" onClick={this.scrollTo}>Constructor</a></li>
                        <li><a href="#section-team" onClick={this.scrollTo}>Team</a></li>
                    </ul>
                </div>
                <div className="follow-us">
                    <ul>
                        <li>Follow Us</li>
                        <li><a href="https://twitter.com/shorthandef" target="_blank" rel="noreferrer noopener"><img src={twitterLogo} /></a></li>
                        <li><a href="https://opensea.io/collection/shorthandef" target="_blank" rel="noreferrer noopener"><img src={openseaLogo} /></a></li>
                    </ul>
                </div>
                <div className="copyrights">
                    <a href="/" className="logo"><img src={logo} /></a>

                    {new Date().getFullYear()} &copy; All Rights Reserved<br />
                    Design by Daria Komlieva<br />
                    Ukraine, Kharkiv
                </div>
            </footer>
        );
    }
}

export default Footer;

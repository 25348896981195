import React from 'react';

class SectionConstructor extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section id="section-constructor">
                <h2>
                    <span>C</span>
                    <span>o</span>
                    <span>n</span>
                    <span>s</span>
                    <span>t</span>
                    <span>r</span>
                    <span>u</span>
                    <span>c</span>
                    <span>t</span>
                    <span>o</span>
                    <span>r</span>
                </h2>
                <p className="subheader">3,500 Dino</p>

                <ul className="content-block">
                    <li><span>Background</span></li>
                    <li><span>Skin</span></li>
                    <li><span>Head Item</span></li>
                    <li><span>Mouth</span></li>
                    <li><span>Paws</span></li>
                    <li><span>Accessories</span></li>
                    <li><span>Eye</span></li>
                    <li><span>Clothing</span></li>
                </ul>
            </section>
        );
    }
}

export default SectionConstructor;

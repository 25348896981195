import React from 'react';
import FontFaceObserver from 'fontfaceobserver';

class SectionRoadmap extends React.Component {
    constructor(props) {
        super(props);
    }

    rebuildBlocks() {
        const fontA = new FontFaceObserver('Press Start 2P');
        const fontB = new FontFaceObserver('Orbitron');

        Promise.all([fontA.load(), fontB.load()]).then(function () {
            document.querySelectorAll('.roadmap-milestone').forEach(function(item) {
                item.style.height = 'auto';
                const newHeight = Math.ceil(item.offsetHeight / 26) * 26;
                item.style.height = newHeight + 'px';

                if (window.innerWidth <= 992) {
                    item.style.width = 'auto';
                    const newWidth = Math.ceil(item.offsetWidth / 26) * 26 - 2*26;
                    item.style.width = newWidth + 'px';
                }
            });
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.rebuildBlocks);

        this.rebuildBlocks();
    }

    render() {
        return (
            <section id="section-roadmap">
                <div className="roadmap-milestone">
                    <div className="milestone-description-container">
                        <div className="milestone-value">15%</div>
                        <div className="milestone-description">
                            <p>We transfer 10 ETH to support “Charity foundation of Serhiy Prytula”</p>

                            <p>Serhiy Prytula has been helping the Ukrainian Army as a private volunteer since Russia occupied Crimea and Donbas in 2014. At first, he helped with the uniforms, armor, and food supplies, later, when these needs started to be covered by the Ministry of Defense, he switched to high-tech technology, drones, and equipment for command posts.</p>
                        </div>
                    </div>
                    <div className="milestone-description-mobile">
                        <p>Serhiy Prytula has been helping the Ukrainian Army as a private volunteer since Russia occupied Crimea and Donbas in 2014. At first, he helped with the uniforms, armor, and food supplies, later, when these needs started to be covered by the Ministry of Defense, he switched to high-tech technology, drones, and equipment for command posts.</p>
                    </div>
                </div>
                <div className="roadmap-milestone reversed">
                    <div className="milestone-description-container">
                        <div className="milestone-value">30%</div>
                        <div className="milestone-description">
                            <p>We transfer 10 ETH to support “Charity foundation of Levy na Jeepe”</p>

                            <p>LNJ assumed the responsibility of providing the fighters of the first front line, as well as the fighters of hot spots. They are in direct communication directly with company, platoon, battalion, commanders and they provide them with the most necessary (cars, walkie-talkies, drones and thermal imagers).</p>
                        </div>
                    </div>
                    <div className="milestone-description-mobile">
                        <p>LNJ assumed the responsibility of providing the fighters of the first front line, as well as the fighters of hot spots. They are in direct communication directly with company, platoon, battalion, commanders and they provide them with the most necessary (cars, walkie-talkies, drones and thermal imagers).</p>
                    </div>
                </div>
                <div className="roadmap-milestone">
                    <div className="milestone-description-container">
                        <div className="milestone-value">50%</div>
                        <div className="milestone-description">
                            <p>We transfer 10 ETH to support foundation “Come Back Alive”</p>

                            <p>Foundation providing competent assistance to the military since 2014. Since the beginning of the full-scale invasion in February 2022, they have multiplied thier military assistance and supported the defenders of Ukraine, who have changed their everyday lives and joined the line of defense.</p>
                        </div>
                    </div>
                    <div className="milestone-description-mobile">
                        <p>Foundation providing competent assistance to the military since 2014. Since the beginning of the full-scale invasion in February 2022, they have multiplied thier military assistance and supported the defenders of Ukraine, who have changed their everyday lives and joined the line of defense.</p>
                    </div>
                </div>
                <div className="roadmap-milestone reversed">
                    <div className="milestone-description-container">
                        <div className="milestone-value">75%</div>
                        <div className="milestone-description">
                            <p>We transfer 10 ETH to support “BEHIND BLUE EYES”</p>

                            <p>Local kids receive a bag of disposable film cameras to document their life in deliberated Ukrainian villages. They present this unique body of work to you, raising funds to fulfill kiddo wishlists in return. Kids learn how to dream, create and achieve in the new ways. The goal is not only to give the kid a chance to vocalize about the war, but also to show that creativity is a powerful tool to achieve own dreams.</p>
                        </div>
                    </div>
                    <div className="milestone-description-mobile">
                        <p>Local kids receive a bag of disposable film cameras to document their life in deliberated Ukrainian villages. They present this unique body of work to you, raising funds to fulfill kiddo wishlists in return. Kids learn how to dream, create and achieve in the new ways. The goal is not only to give the kid a chance to vocalize about the war, but also to show that creativity is a powerful tool to achieve own dreams.</p>
                    </div>
                </div>
                <div className="roadmap-milestone">
                    <div className="milestone-description-container">
                        <div className="milestone-value">100%</div>
                        <div className="milestone-description">
                            <p>We transfer 10 ETH to support the humanitarian movement “UAnimals”</p>

                            <p>The humanitarian movement UAnimals was created in 2016 by community activist Oleksandr Todorchuk. We then started our activities by fighting animal exploitation in circuses on a large scale. Since the beginning of the full scale war they have actively been helping animals within war zones: evacuate, provide feed and medicines for shelters. </p>
                        </div>
                    </div>
                    <div className="milestone-description-mobile">
                        <p>The humanitarian movement UAnimals was created in 2016 by community activist Oleksandr Todorchuk. We then started our activities by fighting animal exploitation in circuses on a large scale. Since the beginning of the full scale war they have actively been helping animals within war zones: evacuate, provide feed and medicines for shelters.</p>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionRoadmap;

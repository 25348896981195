import React from 'react';

import Header from "./Header";
import SectionProject from "./SectionProject";
import SectionRoadmap from "./SectionRoadmap";
import SectionConstructor from "./SectionConstructor";
import SectionTeam from "./SectionTeam";
import Footer from "./Footer";

class App extends React.Component {
    constructor(props) {
        super(props);
    }


    scrollTo(domID) {
        const element = document.querySelector(domID),
              header = document.querySelector('header'),
              headerHeight = header.clientHeight,
              scrollTo = element.offsetTop - headerHeight;

        window.scrollTo({
            top: scrollTo,
            behavior: "smooth"
        });
    }


    render() {
        return (
            <div className="main-container">
                <Header scrollTo={this.scrollTo} />
                <SectionProject scrollTo={this.scrollTo} />
                <SectionRoadmap scrollTo={this.scrollTo} />
                <SectionConstructor scrollTo={this.scrollTo} />
                <SectionTeam scrollTo={this.scrollTo} />
                <Footer scrollTo={this.scrollTo} />
            </div>
        );
    }
}

export default App;

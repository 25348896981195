import React from 'react';

import logo from './img/logo.png';
import cross from './img/cross.svg';
import twitterLogo from "./img/menu-twitter.svg";
import openseaLogo from "./img/menu-opensea.svg";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navOpen: false
        };

        this.scrollTo = this.scrollTo.bind(this);
    }


    scrollTo(e) {
        e.preventDefault();

        const domID = e.currentTarget.getAttribute('href');
        this.props.scrollTo(domID);
    }


    render() {
        return (
            <header>
                <a href="/" className="logo"><img src={logo} /></a>

                <ul>
                    <li><a href="#section-project" onClick={this.scrollTo}>Project</a></li>
                    <li><a href="#section-roadmap" onClick={this.scrollTo}>Roadmap</a></li>
                    <li><a href="#section-constructor" onClick={this.scrollTo}>Constructor</a></li>
                    <li><a href="#section-team" onClick={this.scrollTo}>Team</a></li>
                </ul>

                <a href="https://opensea.io/collection/shorthandef" target="_blank" rel="noreferrer noopener" className="cta-link">Buy a DINO</a>

                <a href="/" className="menu-link" onClick={e => {e.preventDefault(); this.setState({navOpen: true});}}>Me<br />nu</a>

                <nav className={(this.state.navOpen)?('open'):('')}>
                    <div className="nav-header">
                        <a href="/" className="logo"><img src={logo} /></a>
                        <a href="/" className="close" onClick={e => {e.preventDefault(); this.setState({navOpen: false});}}><img src={cross} /></a>
                    </div>

                    <ul className="main-menu">
                        <li><a href="#section-project" onClick={e => {this.setState({navOpen: false}); this.scrollTo(e);}}>Project</a></li>
                        <li><a href="#section-roadmap" onClick={e => {this.setState({navOpen: false}); this.scrollTo(e);}}>Roadmap</a></li>
                        <li><a href="#section-constructor" onClick={e => {this.setState({navOpen: false}); this.scrollTo(e);}}>Constructor</a></li>
                        <li><a href="#section-team" onClick={e => {this.setState({navOpen: false}); this.scrollTo(e);}}>Team</a></li>
                    </ul>

                    <a href="https://opensea.io/collection/shorthandef" target="_blank" rel="noreferrer noopener" className="cta-link">Buy a DINO</a>

                    <ul className="socialLinks">
                        <li><a href="https://twitter.com/shorthandef" target="_blank" rel="noopener noreferrer"><img src={twitterLogo} /></a></li>
                        <li><a href="https://opensea.io/collection/shorthandef" target="_blank" rel="noopener noreferrer"><img src={openseaLogo} /></a></li>
                    </ul>
                </nav>
            </header>
        );
    }
}

export default Header;
